<!-- 工作台 -->
<template>
  <div class="workbench">
    <div class="top_info">
      <div class="user_view">
        <div class="username">
          <p class="name fontColor_theme">{{userName}}，</p>
          <p>祝你开心每一天！</p>
          <p>{{dateValue}}</p>
        </div>
        <div class="img_box">
          <img class="rd_img" :src="require(`../../assets/image/workbench/${randomNum}.png`)" alt="">
        </div>
      </div>
      <div class="data_view">
        <h2 class="col_title">账号概况</h2>

        <div class="sum_box">
          <!-- 品牌数、任务总数、执行中任务、已完成任务
          操作频次统计 -->
          <div class="sum_box_items">
            <p>品牌数</p>
            <countTo :startVal='0' :endVal='statisticsObj.brand_num' :duration='2000' class="count"></countTo> 
          </div>
          <div class="sum_box_items">
            <p>任务总数</p>
            <countTo :startVal='0' :endVal='statisticsObj.task_num' :duration='2000' class="count"></countTo> 
          </div>
          <div class="sum_box_items">
            <p>执行中任务</p>
            <countTo :startVal='0' :endVal='statisticsObj.run_task_num' :duration='2000' class="count"></countTo> 
          </div>
          <div class="sum_box_items">
            <p>已完成任务</p>
            <countTo :startVal='0' :endVal='statisticsObj.complete_task_num' :duration='2000' class="count"></countTo> 
          </div>
        
        <!-- 
          流程：
          新建品牌信息、
         -->
        </div>
      </div>
    </div>
    <!-- 操作流程 -->
    <el-row>
      <el-col :span="24">
        <h2 class="col_title">流程图</h2>
        <div class="oper_process">
          <div class="opp_items" v-for="(item,index) in processList" :key="index">
            <div class="oppi_items oppi_title">
              <div>{{item.title}}</div>
            </div>
            <div class="opopop clearfix">
              <div v-for="(e,i) in item.text" :key="i" :class="`oppi_items_${e.index} oppi_items`">
                <div>{{e.t}}</div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="nothing">

        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import countTo from 'vue-count-to'; // 数字滚动

export default {
  components: {
    countTo,
  },
  data() {
    let userName = this.utils.sessionGetStore('userName')
    let userId = this.utils.sessionGetStore('userId')
    let time = this.utils.getNow()
    return {
      userId,
      userName,
      randomNum: 4,
      dateValue: time,
      processList: [
        {
          title: '1、品牌管理',
          text: [
            { t: '添加品牌', index: 1 },
            { t: '完善信息', index: 2 },
            { t: '品牌看板', index: 3 },
          ],
        },
        {
          title: '2、品牌任务',
          text: [ 
            { t: '确认需求', index: 1 },
            { t: '检验平台数据', index: 2 },
            { t: '新建采集任务', index: 3 },
            { t: '选择数据选项', index: 4 },
            { t: '创建任务', index: 5 },
          ]
        },
        {
          title: '3、任务列表',
          text: [ 
            { t: '选择任务', index: 1 },
            { t: '查看详情', index: 2 },
            { t: '清洗数据', index: 3 },
            { t: '收录数据', index: 4 },
          ]
        },
        {
          title: '4、白名单管理',
          text: [ 
            { t: '添加白名单', index: 1 },
            { t: '导入白名单', index: 2 },
            { t: '导出白名单', index: 3 },
          ]
        },
        {
          title: '5、控价表管理',
          text: [ 
            { t: '添加控价表', index: 1 },
            { t: '导入控价表', index: 2 },
            { t: '导出控价表', index: 3 },
          ]
        },
        {
          title: '6、数据列表',
          text: [ 
            { t: '型号打标', index: 1 },
            { t: '同步白名单', index: 2 },
            { t: '同步控价表', index: 3 },
            { t: '更新数据', index: 4 },
            { t: '创建标签', index: 5 },
            { t: '导出数据', index: 1 },
          ]
        },
        {
          title: '7、投诉记录',
          text: [ 
            { t: '导入投诉信息', index: 1 },
            { t: '编辑投诉内容', index: 2 },
            { t: '更改投诉类型', index: 3 },
            { t: '更新投诉结果', index: 4 },
            { t: '统计投诉数据', index: 5 },
            { t: '导出投诉信息', index: 1 },
          ]
        },
      ],
      statisticsObj: {
        brand_num: 0,
        complete_task_num: 0,
        run_task_num: 0,
        task_num: 0
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      let paramObj = {
        user_id: this.userId
      }
      this.$api.workbenchStatisFunc(paramObj)
      .then(res => {
        // console.log(res)
        if(res.code == 0) {
          Object.keys(this.statisticsObj).forEach(key => {
            this.statisticsObj[key] = Number(res.data[key])
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/workbenchStyle/wbs.scss';
.workbench {
    margin: 10px 50px;

    .top_info {
      display: flex;
      margin-bottom: 15px;
      .user_view {
        height: 200px;
        background: #fff;
        display: flex;
        justify-content: space-between;
        width: 580px;

        .username {
          width: 200px;
          padding: 40px 0 0 20px;
          
          >p {
            margin-bottom: 20px;
            font-size: 14px;
          }
          .name {
            font-size: 20px;
            font-weight: bold;
          }
        }

        .img_box {
          margin-right: 20px;
          width: 400px;
          .rd_img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .data_view {
        flex: 1;
        height: 200px;
        text-align: center;
        background: #fff;
        margin-left: 20px;
        position: relative;

        .sum_box {
          width: 100%;
          height: 100%;
          padding: 20px;
          display: flex;
          justify-content: space-around;

          .sum_box_items {
            padding-top: 50px;
            >p {
              font-size: 18px;
              font-weight: bold;
              margin-bottom: 20px;
              letter-spacing: 1px;
            }
            .count {
              font-size: 20px;
              font-weight: bold;
              color: #409EFF;
            }
          }
        }
      }
    }
    
    // 流程图
    .oper_process {
      padding: 50px 30px 20px;
      background: #fff;
      margin-bottom: 15px;

      .opp_items {
        display: flex;
        .oppi_items {
          float: left;
          width: 16.6667%;
          max-width: 180px;
          text-align: center;
          margin-bottom: 9px;
          padding-right: 15px;
          > div {
            position: relative;
            background: #E8EBEF;
            line-height: 20px;
            padding: 5px 0 5px 8px;
            white-space: nowrap;

            &::before {
              content: ' ';
              display: block;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 15px 0 15px 10px;
              border-color: transparent transparent transparent #E8EBEF;
              position: absolute;
              left: 0;
              top: 0;
              border-left-color: #fff;
              z-index: 1;
            }
            &::after {
              content: ' ';
              display: block;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 15px 0 15px 10px;
              border-color: transparent transparent transparent #E8EBEF;
              position: absolute;
              top: 0;
              right: -10px;
              left: auto;
              z-index: 2;
            }
          }
        }
        .oppi_title {
          >div {
            color: #838A9D;
            font-weight: bold;
            text-align: left;
            padding-left: 20px;

            &::before {
              display: none;
            }
          }
        }

        .opopop {
          flex: 1;
        }

        .oppi_items_1 {
          >div {
            background: #E3F2FD;
            &::after {
              border-left-color: #E3F2FD;
            }
            &:hover {
              background: #1565C0;
              color: #fff;
              &::after {
                border-left-color: #1565C0;
              }
            }
          }
        }

        .oppi_items_2 {
          >div {
            background: #E8F5E9;
            &::after {
              border-left-color: #E8F5E9;
            }
            &:hover {
              background: #43A047;
              color: #fff;
              &::after {
                border-left-color: #43A047;
              }
            }
          }
        }

        .oppi_items_3 {
          >div {
            background: #FFF3E0;
            &::after {
              border-left-color: #FFF3E0;
            }
            &:hover {
              background: #EF6C00;
              color: #fff;
              &::after {
                border-left-color: #EF6C00;
              }
            }
          }
        }

        .oppi_items_4 {
          >div {
            background: #FFEBEE;
            &::after {
              border-left-color: #FFEBEE;
            }
            &:hover {
              background: #E53935;
              color: #fff;
              &::after {
                border-left-color: #E53935;
              }
            }
          }
        }

        .oppi_items_5 {
          >div {
            background: #F3E5F5;
            &::after {
              border-left-color: #F3E5F5;
            }
            &:hover {
              background: #9C27B0;
              color: #fff;
              &::after {
                border-left-color: #9C27B0;
              }
            }
          }
        }
        
      }
    }

    .nothing {
      padding: 200px;
      background: #fff;
    }
}

</style>